import { graphql } from 'gatsby'
import React from 'react'
import { Link } from "gatsby"

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const CapricePage = ({ data }) => {
    const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
    return(
        <Layout>
          <SEO title="Comptoir Café à Hôtel Club Al Moggar" />
          <div class="md:mx-40 md:my-5 mx-3">
            <div class="grid grid-cols-1 mt-8 gap-5">
             <div class="relative svg-icon">
                <Link to={`/realisations-2`}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="absolute left-0 bottom-0 hover:opacity-80" width="40" height="40" viewBox="0 0 20 20" fill="currentColor">
                     <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                    </svg>
                </Link>
                 <h1 class="text-center md:text-2xl text-xl font-bold uppercase mt-10">Comptoir à Boulangerie & Café Caprice</h1>
            </div>
             <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5 mb-6"></div>
             <p class="font-bold">Project Description</p>
             <p class="mb-8">&#10003; Réalisation d’un comptoir  à Café “Caprice“</p>
            </div>
          
            <div class="gl project-gl">
               <Gallery images={images} />
            </div>
          </div>
        </Layout> 
 )
}
export const query = graphql`
query PCaprice {
  allFile (filter:{relativeDirectory: {eq: "img/projects/caprice"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default CapricePage